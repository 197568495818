import React from "react";
import { NavLink } from "react-router-dom";

import blackTop from "../assets/images/blackTop.svg";
import myProfileSettings from "../assets/images/myProfileSettings.svg";
import IconHelp from "../assets/images/Icon-help.svg";
import logoutIcon from "../assets/images/logoutIcon.svg";
import { logout } from "api/api";
import { useErrorHandler } from "react-error-boundary";
export default function ManageSetting() {
  const handleError = useErrorHandler();
  const handleLogout = async () => {
    await logout()
      .then((res) => {
        localStorage.removeItem("bpp_profile");
        localStorage.removeItem("bpp_jwt_token");
        sessionStorage.removeItem("bpp_lovedOne");
        localStorage.removeItem("callingUrl");
        window.location.href = "/";
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  return (
    <div className="mainPage loginPage ">
      <div className="arrowfixed">
        <NavLink to="/MenuScreen">
          {" "}
          <img src={blackTop} className="arrowleft" />
        </NavLink>
      </div>
      <div className="contentBody  pt80">
        <h1>
          <span className="fs36">Manage Settings</span>
        </h1>

        <div className="row  pt-4">
          <div className="col-lg-12">
            <div className="settingbox">
              <ul>
                <li>
                  <NavLink to="/Profile">
                    <img src={myProfileSettings} />
                    Profile Settings
                  </NavLink>
                </li>
                <li>
                  <a href={process.env.REACT_APP_3CX_URL} target="_blank">
                    <img src={IconHelp} />
                    Help
                  </a>
                </li>
                <li>
                  <NavLink to="#">
                    <img alt="" src={logoutIcon} />
                    <div className="d-flex flex-column" onClick={handleLogout}>
                      Logout
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
