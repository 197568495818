import { searchLovedOne } from "api/api";
import { useErrorHandler } from "react-error-boundary";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import noContactImage from "assets/images/no-contact1.svg";
import AutoSuggest from "components/AutoSuggest";
import { changeLovedOne } from "redux/lovedOne";
import Layout from "components/Layout";
import { getProfile } from "utils/localStorage";
import IphonePWAPopup from "components/IphonePWAPopup";
import "assets/scss/lovedOneList.scss";
import loader from "assets/images/loader.gif"

const LovedOneList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showList, setShowList] = useState("");
  const [list, setList] = useState([]);
  const [firstListLength, setFirstListLength] = useState(0);

  const handleError = useErrorHandler();
  const location = useLocation();

  const onClickContact = (item) => {
    dispatch(changeLovedOne(item));
    // navigate("/MenuScreen", { state: item, replace: true });
    window.location = "/MenuScreen";
  };

  useEffect(() => {
    const callingUrl = localStorage.getItem("callingUrl");
    const isLoggedUser = localStorage.getItem("bpp_profile");

    console.log("calling url menu screen", callingUrl, JSON.parse(isLoggedUser));

    // Check if both values exist
    if (callingUrl && isLoggedUser) {
      // Redirect to the add video call screen
      console.log("redirect user to video call screen");
      navigate('/' + callingUrl);
    }
  }, []); // Run effect on params change or component mount


  useEffect(() => {
    setLoading(true);
    searchLovedOne("")
      .then((res) => {
        setLoading(false);
        setList(res.data);
        if (firstListLength == 0) {
          setFirstListLength(res.data.length);
        }
        setShowList(res.data);
      })
      .catch((error) => {
        console.log("error", error);
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  }, []);
  const filteredList = (updated_list) => {
    setShowList(updated_list);
  };
  let bpp_profile = getProfile();

  return (
    <>
      <IphonePWAPopup />
      <Layout
        headerText={
          bpp_profile?.lovedOneList.length == 0
            ? "My <b>Contacts</b>"
            : "My <b>Contact</b>"
        }
      >
        <div className="pt80">
          <div className="parent">
            <div className="contentboxgray11">
              <br />
            </div>
          </div>

          {isLoading ? (
            // <Spinner animation="border" variant="primary" />
            <div className="loader-container">
              <img src={loader} alt="Loading..." className="custom-loader" />
            </div>
          ) : (
            <div className="contentBody contentbodymedia primaryadmin">
              <div className="">
                <div className="row justify-content-center">
                  <AutoSuggest data={list} getList={filteredList} />

                  {showList.length ? (
                    showList.map((lovedOne) => (
                      <div
                        className="media boxmediawhite"
                        onClick={() => {
                          onClickContact(lovedOne);
                        }}
                        key={lovedOne.id}
                      >
                        <div className="imghoverbox">
                          <img
                            className="align-self-center imageover"
                            src={lovedOne.photo}
                            alt={lovedOne.first_name}
                            title={lovedOne.first_name}
                          />
                        </div>
                        <div className="media-body">
                          <h5 className="mt-0 mb-0">{`${lovedOne.first_name} ${lovedOne.last_name}`}</h5>
                        </div>
                        {!lovedOne.menu_list.menu_photos && lovedOne.photoUnchecked ? (
                          <div className="imagesLabelNo mediaarrow">
                            {`${lovedOne.photoUnchecked} New Photo${lovedOne.photoUnchecked > 1 ? "s" : ""
                              }!`}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))
                  ) : (
                    <div className=" text-center nocontact">
                      <img
                        src={noContactImage}
                        style={{ width: "25%" }}
                        alt="no contact"
                      />
                      <h3 className="pt-4">No results found</h3>
                    </div>
                  )}
                  {firstListLength == 1 ? (
                    <h5 className="pt-4 setmax-width">
                      You only have one Senior with a Big<b>Purple</b>Phone.
                      When you add others they will appear in this list.
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};
export default LovedOneList;
