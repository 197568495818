// import { logout } from "api/api";
import { useEffect } from "react";
import NotFound from "screens/NotFound";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  let errorCodes = ["400", "401", 400, 401];
  useEffect(() => {
    if (error?.response?.data?.statusCode == "401") {
      localStorage.removeItem("bpp_profile");
      localStorage.removeItem("bpp_jwt_token");
      localStorage.removeItem("callingUrl");
      window.location.assign("/");
    }
  }, []);

  return errorCodes.includes(error?.response?.data?.statusCode) ? (
    false
  ) : (
    <div role="alert" className="text-center">
      <NotFound
        serverError
        resetErrorBoundary={resetErrorBoundary}
        error={error.response?.data}
      />
    </div>
  );
}
